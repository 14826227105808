import React, { useState } from "react";
import { TableCell, TextField } from "@mui/material";
import "../../../assets/style.css";

interface DTDataGridTextEditProps {
  text: string;
  cellKey: string;
  alignmentClass: string | undefined;
  dataChangeFunction?: (e: any, item : any) => void;
  rowIndex: number,
  itemName: string,
  editOnClick: boolean | undefined
}

const DTDataGridTextEdit: React.FC<DTDataGridTextEditProps> = (
  props: DTDataGridTextEditProps
) => {
  const [inEdit, setInEdit] = useState(false);

  return (
    <>
      {
        <TableCell key={props.cellKey} >
            {inEdit && props.editOnClick ? (
              <>
              <div key={props.cellKey} className={[props.alignmentClass, "height100"].join(" ")}>
                <TextField
                  name={props.itemName}
                  key={props.cellKey}
                  margin="none"
                  value={props.text ?? ""}
                  fullWidth
                  variant="outlined"
                  onBlur={() => setInEdit(false)}
                  onChange={(e) => props.dataChangeFunction ? props.dataChangeFunction(e, props.rowIndex) : null}
                />
                </div>
              </>
            ) : (
              <div key={props.cellKey} className={["height100"].join(" ")}  onClick={() => props.editOnClick ? setInEdit(!inEdit) : null}>{props.text}</div>
            )}
          
        </TableCell>
      }
    </>
  );
};

export default DTDataGridTextEdit;
