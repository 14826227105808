import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPackage, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

// This action creator gets the server config values from an api call or state
const saveConfigPackagesData = (configId: string, updatedPackages: IPackage[], token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.SAVE_PACKAGES });

        try {
            const { data: pluginConfiguration } = getState().configPackages;;
            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            if (!updatedPackages || updatedPackages.length === 0) {
                throw new Error("No packages to save");
            }

            const updatedConfig = {
                ...pluginConfiguration.Configuration,
                Packages: updatedPackages
            }

            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            }

            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_PACKAGES_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

            return Promise.resolve();  
        } catch (error: any) {
            const payloadErrorMessage = `Failed to save packages data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_PACKAGES_FAILURE,
                payload: payloadErrorMessage,
            });

            return Promise.reject(payloadErrorMessage);
        }
    }
}

export default saveConfigPackagesData;