import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Box,Snackbar, SnackbarCloseReason, Typography } from "@mui/material";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useSelector } from "react-redux";
import { IExpression } from "../../models/configuration/plugin/IConfiguration";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import { RootState } from "../../state/store";
import ExpressionsControl from "../../components/shared/config/ExpressionsControl";
import EncompassService from "../../services/encompassService";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import "../../assets/style.css";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";

const ConfigSendValidations: React.FC = () => {

    // 
    const { getConfigSendValidationsData, saveConfigSendValidationsData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configSendValidations);

    // Send Validations State
    const [sendValidations, saveSendValidations] = React.useState<IExpression[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');

    // Refs
    const hasFetchedData = useRef(false);

    // State Confirmations
    const [open, setOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alert, setAlert] = useState('');

    // Fetch Data
    const fetchPageData = (configId: string) => {

        if (!hasFetchedData.current) {
            setSelectedConfigId(configId);
            getConfigSendValidationsData(configId, accessToken);
            hasFetchedData.current = true;
        }
    }

    // Handle Fetched Data
    useEffect(() => {
        if (!saveError && data) {
            saveSendValidations(data.Configuration?.SendValidations);
        }
    }, [data, saveError]);

    // Reducing the useEffect need 
    const configId = EncompassService.getConfigId() as string;
    fetchPageData(configId);

    const updateSendValidations = (updatedSendValidations: IExpression[]) => {
        saveSendValidations(updatedSendValidations);
    }

    const save = async () => {
        try {
            await saveConfigSendValidationsData(selectedConfigId, sendValidations, accessToken);
            openAlert("Successfully Saved Changes");
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            setSaveError(true);
            setErrorMessage(error);
        }
    } 

    // Alerts
    const handleSnackBarClose = (_event: SyntheticEvent | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const openAlert = (message: string) => { setAlert(message); setOpen(true); }

    return (
        <>
            <section>
                <div>
                    {loading && <ConfigLoad loading={loading} message="Loading Configuration" />}
                </div>
                <div></div>

                <Box
                    mt={1} component="main" sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                    }}
                >
                    <ConfigTopBar save={save} error={error || errorMessage || null}/>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',  margin: '0px 15px 0px' }}>                       
                        <Typography variant="h6">Expressions</Typography> 
                        <Typography variant="h6"  sx={{ color: (theme) => theme.palette.warning.main }} 
                            component="h1" align="center" gutterBottom>    
                            Any 'False' evaluations will Halt a DocuTech request
                        </Typography>    
                    </Box>    

                    <ExpressionsControl
                        expressions={sendValidations}
                        updateParentExpressions={updateSendValidations}
                    />
                </Box>
                <ConfigBottomBar save={save} />
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={4000} onClose={handleSnackBarClose}>
                    <Alert severity={alert.includes("Successful") ? "success" : "error"} variant="filled">
                        {alert}
                    </Alert>
                </Snackbar>
            </section>
        </>
    );
};

export default ConfigSendValidations;