import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import { Action, ActionType } from "../actions";

interface DeliveryTypesState {
    loading: boolean;
    error: string | null;
    data: IPluginConfiguration | null;
}

const initialState = { loading: false, error: null, data: null }

const configDeliveryTypesReducer = (
    state: DeliveryTypesState = initialState,
    action: Action
): DeliveryTypesState => {

    switch (action.type) {
        // GET DATA
        case ActionType.GET_DELIVERY_TYPES:
            return { loading: true, error: null, data: null };
        case ActionType.GET_DELIVERY_TYPES_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_DELIVERY_TYPES_SUCCESS:
            return { loading: false, error: null, data: action.payload }

        // SAVE DATA
        case ActionType.SAVE_DELIVERY_TYPES:
            return { ...state, loading: true, error: null, data: state.data };
        case ActionType.SAVE_DELIVERY_TYPES_FAILURE:
            return { loading: false, error: action.payload, data: state.data }
        case ActionType.SAVE_DELIVERY_TYPES_SUCCESS: return { loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configDeliveryTypesReducer;