import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Link,
  Grid,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  SnackbarCloseReason,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import EncompassService from "../../services/encompassService";
import { IDefaultValues } from "../../models/configuration/plugin/IConfiguration";

const ConfigMisc: React.FC = () => {
  //
  const { getConfigMiscData, saveConfigMiscData } = useActions();
  const { loading, error, data } = useTypedSelector(
    (state) => state.configMiscDefaults
  );
  const [miscDefaultData, setMiscDefaultData] = useState<IDefaultValues | null>(
    data?.Configuration?.DefaultValues || null
  );
  const accessToken = useTypedSelector((state) => state.appSlice.accessToken);
  // Misc state
  const [selectedConfigId, setSelectedConfigId] = useState<string>("");
  const [requestUri, setRequestUri] = useState("");
  const [pluginUri, setPluginUri] = useState("");
  const [tokenUri, setTokenUri] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");
  const [saveError, setSaveError] = useState<boolean>(false);

  const hasFetchedData = useRef(false);

  const fetchMiscData = useCallback(
    (configId: string) => {
      getConfigMiscData(configId, accessToken);
    },
    [getConfigMiscData, accessToken]
  );

  //   // Save Page Data
  const saveMiscConfigData = useCallback(
    async (configId: string, data: IDefaultValues) => {
      saveConfigMiscData(configId, data, accessToken);
    },
    [saveConfigMiscData, accessToken]
  );

  useEffect(() => {
    if(error) {
      setAlert(error);
      setOpen(true);
    }
  },[error])

  useEffect(() => {
    if (!hasFetchedData.current && !data) {
      const configId = EncompassService.getConfigId() as string;
      setSelectedConfigId(configId);
      const delayFetch = setTimeout(() => {
        fetchMiscData(configId);
        initializeScreenData();
        hasFetchedData.current = true;
      }, 500);
      return () => clearTimeout(delayFetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Data
  useEffect(() => {
    if (!saveError && data != null) {
      setMiscDefaultData(data?.Configuration?.DefaultValues); // Only update local data if there is no save Error condition
      initializeScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.Configuration?.DefaultValues]);

  const updateMiscData = (key: string, value: any) => {
    setMiscDefaultData((prevData) => {
      if (!prevData) return null;
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  // Toast
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setAlert("");
    setSaveError(false);
  };

  function cacheEndpoints() {
    localStorage.setItem("requestUri", requestUri);
    localStorage.setItem("pluginUri", pluginUri);
    localStorage.setItem("tokenUri", tokenUri);
  }

  function initializeScreenData() {
    setRequestUri(
      localStorage.getItem("requestUri") ||
        (EncompassService.getRequestEndpoint() as string)
    );
    setPluginUri(
      (EncompassService.getPluginEndpoint() as string)
    );
    setTokenUri(
      localStorage.getItem("tokenUri") ||
        (EncompassService.getTokenEndpoint() as string)
    );
    if (!selectedConfigId) {
      setSelectedConfigId(getConfigId());
    }
  }

  const getConfigId = () => {
    if (selectedConfigId) return selectedConfigId;
    return EncompassService.getConfigId() as string;
  };

  const save = async () => {
    cacheEndpoints();
    try {
      saveMiscConfigData(getConfigId(), miscDefaultData as IDefaultValues);
      setAlert("Successfully Saved Misc Config Changes");
      setOpen(true);
      setSaveError(false);
    } catch (e) {      
      setSaveError(true);
    }
  };

  return (
    <section>
      <div>
        {loading && (
          <ConfigLoad loading={loading} message="Loading Configuration" />
        )}
      </div>
      <Box
        mt={1}
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
          minHeight: "100vh",
          margin: "2px",
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <Link href="#">Encompass UI Integration</Link>
          <Link
            href="#"
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            Pushback Field Mappings
          </Link>
        </Box>
        <Card sx={{ margin: "10px" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Misc Configuration
            </Typography>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Request</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography>SaveLoanBeforeClosing</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={miscDefaultData?.SaveLoanBeforeDisclosing || ""}
                      onChange={(event) =>
                        updateMiscData(
                          "SaveLoanBeforeDisclosing",
                          event.target.value
                        )
                      }
                    >
                      <MenuItem sx={{ padding: "2px 8px" }} value="Always">
                        Always
                      </MenuItem>
                      <MenuItem
                        sx={{ padding: "2px 8px" }}
                        value="WhenRecalculationsAreNotComplete"
                      >
                        WhenRecalculationsAreNotComplete
                      </MenuItem>
                      <MenuItem sx={{ padding: "2px 8px" }} value="Never">
                        Never
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Request Type</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={miscDefaultData?.RequestTypeAvailability || ""}
                      onChange={(event) =>
                        updateMiscData(
                          "RequestTypeAvailability",
                          event.target.value
                        )
                      }
                    >
                      <MenuItem sx={{ padding: "2px 8px" }} value="LightsOut">
                        LightsOut
                      </MenuItem>
                      <MenuItem sx={{ padding: "2px 8px" }} value="LightsOn">
                        LightsOn
                      </MenuItem>
                      <MenuItem sx={{ padding: "2px 8px" }} value="Both">
                        Both
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Default Request</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={miscDefaultData?.DefaultRequestType || ""}
                      onChange={(event) =>
                        updateMiscData("DefaultRequestType", event.target.value)
                      }
                    >
                      <MenuItem sx={{ padding: "2px 8px" }} value="LightsOut">
                        LightsOut
                      </MenuItem>
                      <MenuItem sx={{ padding: "2px 8px" }} value="LightsOn">
                        LightsOn
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Response</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography>PreviewAttachmentsAlways</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      value={
                        miscDefaultData?.PreviewAttachmentsAlways
                          ? "true"
                          : "false"
                      }
                      onChange={(event) =>
                        updateMiscData(
                          "PreviewAttachmentsAlways",
                          event.target.value === "true"
                        )
                      }
                    >
                      <MenuItem sx={{ padding: "2px 8px" }} value="true">
                        True
                      </MenuItem>
                      <MenuItem sx={{ padding: "2px 8px" }} value="false">
                        False
                      </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity={alert.includes("Successful") ? "success" : "error"}
                variant="filled"
              >
                {alert}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>
        <ConfigBottomBar save={save} />
      </Box>
    </section>
  );
};

export default ConfigMisc;
