// Build me a reducer that will handle the validation of the send form
import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import { Action, ActionType } from "../actions";

interface SendValidationsState {
    loading: boolean;
    error: string | null;
    data: IPluginConfiguration | null;
}       

const initialState = { loading: false, error: null, data: null }

const configSendValidationsReducer = (
    state: SendValidationsState = initialState,
    action: Action
): SendValidationsState => {

    switch (action.type) {
        // GET DATA
        case ActionType.GET_SEND_VALIDATIONS:
            return { loading: true, error: null, data: null };
        case ActionType.GET_SEND_VALIDATIONS_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_SEND_VALIDATIONS_SUCCESS:
            return { loading: false, error: null, data: action.payload }

        // SAVE DATA
        case ActionType.SAVE_SEND_VALIDATIONS:
            return { ...state, loading: true, error: null, data: state.data };
        case ActionType.SAVE_SEND_VALIDATIONS_FAILURE:
            return { loading: false, error: action.payload, data: state.data }
        case ActionType.SAVE_SEND_VALIDATIONS_SUCCESS: return { loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configSendValidationsReducer;