import ApiService from "./apiService";
import ElliHost from "./elliHost";
import { setOriginVals } from "../state/originSlice";
import { setAuthToken, setConfigId, setEncompassRoles, setUserId, setUserPersonas } from "../state/appSlice";
import { store } from "../state/store"
import { AxiosResponse } from "axios";

const baseURL = process.env.REACT_APP_EPC_API as string; // ToDo: this should be fetched from the config

class OriginService {

    private apiService: ApiService;
    private elliHost;

    constructor() {
        this.apiService = new ApiService(baseURL);
        this.elliHost = ElliHost.getInstance();
    }

    private async getOriginApi(elliPat: string, originId: string): Promise<AxiosResponse<unknown, any>> {
        const apiOrigin = await this.apiService.get(`origins/${originId}`, {
            headers: {
                "X-Eli-Pat": elliPat
            }
        });
        return apiOrigin;
    }

    public async buildOrigin(): Promise<any> { 
        const host: any = await this.elliHost.getOrigin();
        store.dispatch(setOriginVals({ pat: host.partnerAccessToken, id: host.id }))
        const origin :any = await this.getOriginApi(host.partnerAccessToken, host.id)  
        store.dispatch(setAuthToken({token: origin?.data?.result?.oAuthToken?.accessToken, expires: origin?.data?.result?.oAuthToken?.expirationDateTimeUTC})) 
        store.dispatch(setConfigId(origin?.data?.result?.configId))
        store.dispatch(setUserPersonas(origin?.data?.result?.pointOfContact?.personas))
        store.dispatch(setUserId(origin?.data?.result?.pointOfContact?.email))
        const roles = await this.elliHost.getRoles();
        store.dispatch(setEncompassRoles(roles)); 
        return origin;
    }

};

export default OriginService;


