import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";


// This action creator gets the server config values from an api call or state
const getConfigPackagesData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_PACKAGES });

        const plugInData = getState().configPlugInData.data;
        if (plugInData !== null) { // Checking for exiting state data  
            dispatch({
                type: ActionType.GET_PACKAGES_SUCCESS,
                payload: plugInData
            });
            return;
        }

        try {

            const payload = await new PluginService().getConfig<IPluginConfiguration>(token, "plugin", configId);
            dispatch({
                type: ActionType.GET_PACKAGES_SUCCESS,
                payload: payload
            });

        } catch (error: any) {

            console.error("Get Packages Data");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to fetch packages Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_PACKAGES_FAILURE,
                    payload: payloadErrorMessage,
                });
            }
        }
    }
}

export default getConfigPackagesData;