import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IExpression, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

// This action creator gets the server config values from an api call or state
const saveConfigSendValidationsData = (configId: string, updatedSendValidations: IExpression[], token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.SAVE_SEND_VALIDATIONS });

        try {
            const { data: pluginConfiguration } = getState().configSendValidations;
            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            if (!updatedSendValidations || updatedSendValidations.length === 0) {
                throw new Error("No Send Validation to save");
            }

            const updatedConfig = {
                ...pluginConfiguration.Configuration,
                SendValidations: updatedSendValidations
            }

            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            }

            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_SEND_VALIDATIONS_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

            return Promise.resolve();
        } catch (error: any) {
            const payloadErrorMessage = `Failed to save send Validations types data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_SEND_VALIDATIONS_FAILURE,
                payload: payloadErrorMessage,
            });

            return Promise.reject(payloadErrorMessage);
        }
    }
}

export default saveConfigSendValidationsData;