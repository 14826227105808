import { IBundleConfiguration, IDocumentMappingConfiguration, IEventBundleConfiguration } from '../../models/configuration/bundles/IBundles';
import { IImpersonation, IPluginConfiguration } from '../../models/configuration/plugin/IConfiguration';
import IIntegration from '../../models/configuration/servers/IIntegration';

export enum ActionType {
    // All Plug In Data
    GET_CONFIG_PLUGIN_DATA = 'get_plugin_data', GET_CONFIG_PLUGIN_DATA_SUCCESS = 'get_plugin_data_success', GET_CONFIG_PLUGIN_DATA_FAILURE = 'get_plugin_data_failure',
    SAVE_CONFIG_PLUGIN_DATA = 'save_plugin_data', SAVE_CONFIG_PLUGIN_DATA_SUCCESS = 'save_plugin_data_success', SAVE_CONFIG_PLUGIN_DATA_FAILURE = 'save_plugin_data_failure',
    // Config Servers
    GET_CONFIG_SERVERS = 'get_config_servers', GET_CONFIG_SERVERS_SUCCESS = 'get_config_servers_success', GET_CONFIG_SERVERS_FAILURE = 'get_config_servers_failure',
    SAVE_CONFIG_SERVERS = 'save_config_servers', SAVE_CONFIG_SERVERS_SUCCESS = 'save_config_servers_success', SAVE_CONFIG_SERVERS_FAILURE = 'save_config_servers_failure',
    // Other Controls    
    GET_OTHER_CONTROLS = 'get_other_controls', GET_OTHER_CONTROLS_SUCCESS = 'get_other_controls_success', GET_OTHER_CONTROLS_FAILURE = 'get_other_controls_failure',
    SAVE_OTHER_CONTROLS = 'save_other_controls', SAVE_OTHER_CONTROLS_SUCCESS = 'save_other_controls_success', SAVE_OTHER_CONTROLS_FAILURE = 'save_other_controls_failure',
    // Packages
    GET_PACKAGES = 'get_packages', GET_PACKAGES_SUCCESS = 'get_packages_success', GET_PACKAGES_FAILURE = 'get_packages_failure',
    SAVE_PACKAGES = 'save_packages', SAVE_PACKAGES_SUCCESS = 'save_packages_success', SAVE_PACKAGES_FAILURE = 'save_packages_failure',
    // Delivery Types
    GET_DELIVERY_TYPES = 'get_delivery_types', GET_DELIVERY_TYPES_SUCCESS = 'get_delivery_types_success', GET_DELIVERY_TYPES_FAILURE = 'get_delivery_types_failure',
    SAVE_DELIVERY_TYPES = 'save_delivery_types', SAVE_DELIVERY_TYPES_SUCCESS = 'save_delivery_types_success', SAVE_DELIVERY_TYPES_FAILURE = 'save_delivery_types_failure',
    // Impersonation
    GET_IMPERSONATION = 'get_impersonation', GET_IMPERSONATION_SUCCESS = 'get_impersonation_success', GET_IMPERSONATION_FAILURE = 'get_impersonation_failure',
    // Send Validations
    GET_SEND_VALIDATIONS = 'get_send_validations', GET_SEND_VALIDATIONS_SUCCESS = 'get_send_validations_success', GET_SEND_VALIDATIONS_FAILURE = 'get_send_validations_failure',
    SAVE_SEND_VALIDATIONS = 'save_send_validations', SAVE_SEND_VALIDATIONS_SUCCESS = 'save_send_validations_success', SAVE_SEND_VALIDATIONS_FAILURE = 'save_send_validations_failure',
    // Messages
    GET_MESSAGES = 'get_messages', GET_MESSAGES_SUCCESS = 'get_messages_success', GET_MESSAGES_FAILURE = 'get_messages_failure',
    SAVE_MESSAGES = 'save_messages', SAVE_MESSAGES_SUCCESS = 'save_messages_success', SAVE_MESSAGES_FAILURE = 'save_messages_failure',
    // Bundling
    GET_CONFIG_BUNDLE_DATA = 'get_config_bundle_data', GET_CONFIG_BUNDLE_DATA_SUCCESS = 'get_config_bundle_data_success', GET_CONFIG_BUNDLE_DATA_FAILURE = 'get_config_bundle_data_failure',
    SAVE_CONFIG_BUNDLE_DATA = 'save_config_bundle_data', SAVE_CONFIG_BUNDLE_DATA_SUCCESS = 'save_config_bundle_data_success', SAVE_CONFIG_BUNDLE_DATA_FAILURE = 'save_config_bundle_data_failure',
    // Bundling Event
    GET_CONFIG_BUNDLE_EVENT_DATA = 'get_config_bundle_event_data', GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS = 'get_config_bundle_event_data_success', GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE = 'get_config_bundle_event_data_failure',
    // Document Mapping
    GET_DOCUMENT_MAPPING_DATA = 'get_document_mapping_data', GET_DOCUMENT_MAPPING_DATA_SUCCESS = 'get_document_mapping_data_success', GET_DOCUMENT_MAPPING_DATA_FAILURE = 'get_document_mapping_data_failure',
    // Misc
    GET_CONFIG_MISC = 'get_config_misc', GET_CONFIG_MISC_SUCCESS = 'get_config_misc_success', GET_CONFIG_MISC_FAILURE = 'get_config_misc_failure',
    SAVE_CONFIG_MISC = 'save_config_misc', SAVE_CONFIG_MISC_SUCCESS = 'save_config_misc_success', SAVE_CONFIG_MISC_FAILURE = 'save_config_misc_failure',
}

// Configuration Area --> (Config Servers Screen)
interface getConfigServersAction { type: ActionType.GET_CONFIG_SERVERS; } // This is what is happening during the fetch of the data
interface getConfigServersFailureAction { type: ActionType.GET_CONFIG_SERVERS_FAILURE; payload: string; }
interface getConfigServersSuccessAction { type: ActionType.GET_CONFIG_SERVERS_SUCCESS; payload: IIntegration; }
interface saveConfigServersAction { type: ActionType.SAVE_CONFIG_SERVERS }
interface saveConfigServersFailureAction { type: ActionType.SAVE_CONFIG_SERVERS_FAILURE, payload: string; }
interface saveConfigServersSuccessAction { type: ActionType.SAVE_CONFIG_SERVERS_SUCCESS, payload: IIntegration; }
export type GetConfigActions = getConfigServersAction | getConfigServersSuccessAction | getConfigServersFailureAction;
export type SaveConfigActions = saveConfigServersAction | saveConfigServersSuccessAction | saveConfigServersFailureAction;

// Configuration Area --> Other Controls
interface getConfigOtherControls { type: ActionType.GET_OTHER_CONTROLS; }
interface getConfigOtherControlsFailureAction { type: ActionType.GET_OTHER_CONTROLS_FAILURE; payload: string }
interface getConfigOtherControlsSuccessAction { type: ActionType.GET_OTHER_CONTROLS_SUCCESS; payload: IPluginConfiguration }
interface saveConfigOtherControls { type: ActionType.SAVE_OTHER_CONTROLS; }
interface saveConfigOtherControlsFailureAction { type: ActionType.SAVE_OTHER_CONTROLS_FAILURE; payload: string }
interface saveConfigOtherControlsSuccessAction { type: ActionType.SAVE_OTHER_CONTROLS_SUCCESS; payload: IPluginConfiguration }
export type GetOtherControlsActions = getConfigOtherControls | getConfigOtherControlsFailureAction | getConfigOtherControlsSuccessAction;
export type SaveOtherControlsActions = saveConfigOtherControls | saveConfigOtherControlsFailureAction | saveConfigOtherControlsSuccessAction;

// Configuration Area --> Packages  
interface getConfigPackagesDataAction { type: ActionType.GET_PACKAGES; } // This is what is happening during the fetch of the data
interface getConfigPackagesDataFailureAction { type: ActionType.GET_PACKAGES_FAILURE; payload: string; }
interface getConfigPackagesDataSuccessAction { type: ActionType.GET_PACKAGES_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigPackagesDataAction { type: ActionType.SAVE_PACKAGES }
interface saveConfigPackagesDataFailureAction { type: ActionType.SAVE_PACKAGES_FAILURE, payload: string; }
interface saveConfigPackagesDataSuccessAction { type: ActionType.SAVE_PACKAGES_SUCCESS, payload: IPluginConfiguration; }
export type GetPackagesActions = getConfigPackagesDataAction | getConfigPackagesDataFailureAction | getConfigPackagesDataSuccessAction;
export type SavePackagesActions = saveConfigPackagesDataAction | saveConfigPackagesDataFailureAction | saveConfigPackagesDataSuccessAction;

// delivery --> (Delivery Types)
interface getConfigDeliveryTypesAction { type: ActionType.GET_DELIVERY_TYPES; } // This is what is happening during the fetch of the data
interface getConfigDeliveryTypesFailureAction { type: ActionType.GET_DELIVERY_TYPES_FAILURE; payload: string; }
interface getConfigDeliveryTypesSuccessAction { type: ActionType.GET_DELIVERY_TYPES_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigDeliveryTypesAction { type: ActionType.SAVE_DELIVERY_TYPES }
interface saveConfigDeliveryTypesFailureAction { type: ActionType.SAVE_DELIVERY_TYPES_FAILURE, payload: string; }
interface saveConfigDeliveryTypesSuccessAction { type: ActionType.SAVE_DELIVERY_TYPES_SUCCESS, payload: IPluginConfiguration; }
export type GetDeliveryTypesActions = getConfigDeliveryTypesAction | getConfigDeliveryTypesFailureAction | getConfigDeliveryTypesSuccessAction;
export type SaveDeliveryTypesActions = saveConfigDeliveryTypesAction | saveConfigDeliveryTypesFailureAction | saveConfigDeliveryTypesSuccessAction;

// Send Validations
interface getConfigSendValidationsAction { type: ActionType.GET_SEND_VALIDATIONS; }
interface getConfigSendValidationsFailureAction { type: ActionType.GET_SEND_VALIDATIONS_FAILURE; payload: string; }
interface getConfigSendValidationsSuccessAction { type: ActionType.GET_SEND_VALIDATIONS_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigSendValidationsAction { type: ActionType.SAVE_SEND_VALIDATIONS }
interface saveConfigSendValidationsFailureAction { type: ActionType.SAVE_SEND_VALIDATIONS_FAILURE, payload: string; }
interface saveConfigSendValidationsSuccessAction { type: ActionType.SAVE_SEND_VALIDATIONS_SUCCESS, payload: IPluginConfiguration; }
export type GetSendValidationsActions = getConfigSendValidationsAction | getConfigSendValidationsFailureAction | getConfigSendValidationsSuccessAction;
export type SaveSendValidationsActions = saveConfigSendValidationsAction | saveConfigSendValidationsFailureAction | saveConfigSendValidationsSuccessAction;

// Plug in Data
interface getConfigPluginDataAction { type: ActionType.GET_CONFIG_PLUGIN_DATA; } // This is what is happening during the fetch of the data
interface getConfigPluginDataFailureAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_FAILURE; payload: string; }
interface getConfigPluginDataSuccessAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigPluginDataAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA }
interface saveConfigPluginDataFailureAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE, payload: string; }
interface saveConfigPluginDataSuccessAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS, payload: IPluginConfiguration; }
export type GetPluginActions = getConfigPluginDataAction | getConfigPluginDataFailureAction | getConfigPluginDataSuccessAction;
export type SavePluginActions = saveConfigPluginDataAction | saveConfigPluginDataFailureAction | saveConfigPluginDataSuccessAction;

// Impersonation
interface getConfigImpersonationAction { type: ActionType.GET_IMPERSONATION; } // This is what is happening during the fetch of the data
interface getConfigImpersonationFailureAction { type: ActionType.GET_IMPERSONATION_FAILURE; payload: string; }
interface getConfigImpersonationSuccessAction { type: ActionType.GET_IMPERSONATION_SUCCESS; payload: IImpersonation; }
export type GetImpersonationActions = getConfigImpersonationAction | getConfigImpersonationFailureAction | getConfigImpersonationSuccessAction;

// Messages
interface getConfigMessagesAction { type: ActionType.GET_MESSAGES; } // This is what is happening during the fetch of the data
interface getConfigMessagesFailureAction { type: ActionType.GET_MESSAGES_FAILURE; payload: string; }
interface getConfigMessagesSuccessAction { type: ActionType.GET_MESSAGES_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigMessagesAction { type: ActionType.SAVE_MESSAGES }
interface saveConfigMessagesFailureAction { type: ActionType.SAVE_MESSAGES_FAILURE, payload: string; }
interface saveConfigMessagesSuccessAction { type: ActionType.SAVE_MESSAGES_SUCCESS, payload: IPluginConfiguration; }
export type GetMessagesActions = getConfigMessagesAction | getConfigMessagesFailureAction | getConfigMessagesSuccessAction;
export type SaveMessagesActions = saveConfigMessagesAction | saveConfigMessagesFailureAction | saveConfigMessagesSuccessAction;

// Configuration - Document Bundles
interface getConfigBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_DATA; }
interface getConfigBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface getConfigBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
interface saveConfigBundlesAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA; }
interface saveConfigBundlesFailureAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface saveConfigBundlesSuccessAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
export type GetConfigBundleActions = getConfigBundlesAction | getConfigBundlesFailureAction | getConfigBundlesSuccessAction;
export type SaveConfigBundleActions = saveConfigBundlesAction | saveConfigBundlesFailureAction | saveConfigBundlesSuccessAction;

// Configuration - Document Event Bundles
interface getConfigEventBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA; }
interface getConfigEventBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS; payload: IEventBundleConfiguration; }
interface getConfigEventsBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE; payload: string; }
export type GetConfigEventBundleActions = getConfigEventBundlesAction | getConfigEventBundlesSuccessAction | getConfigEventsBundlesFailureAction;

// Configuration - Document Event Bundles
interface getDocumentMappingAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA; }
interface getDocumentMappingSuccessAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_SUCCESS; payload: IDocumentMappingConfiguration; }
interface getDocumentMappingFailureAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_FAILURE; payload: string; }
export type GetDocumentMappingActions = getDocumentMappingAction | getDocumentMappingSuccessAction | getDocumentMappingFailureAction;

// Misc
interface getConfigMiscAction { type: ActionType.GET_CONFIG_MISC; }
interface getConfigMiscFailureAction { type: ActionType.GET_CONFIG_MISC_FAILURE; payload: string; }
interface getConfigMiscSuccessAction { type: ActionType.GET_CONFIG_MISC_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigMiscAction { type: ActionType.SAVE_CONFIG_MISC; }
interface saveConfigMiscSuccessAction { type: ActionType.SAVE_CONFIG_MISC_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigMiscFailureAction { type: ActionType.SAVE_CONFIG_MISC_FAILURE; payload: string; }
export type GetConfigMiscActions = getConfigMiscAction | getConfigMiscFailureAction | getConfigMiscSuccessAction;
export type SaveConfigMiscActions = saveConfigMiscAction | saveConfigMiscSuccessAction | saveConfigMiscFailureAction;

export type Action = 
    GetConfigActions | SaveConfigActions |
    GetOtherControlsActions | SaveOtherControlsActions |
    GetPluginActions | SavePluginActions |
    GetPackagesActions | SavePackagesActions |
    GetDeliveryTypesActions | SaveDeliveryTypesActions |
    GetImpersonationActions |
    GetSendValidationsActions | SaveSendValidationsActions |
    GetMessagesActions | SaveMessagesActions |
    GetConfigBundleActions | SaveConfigBundleActions |
    GetConfigEventBundleActions |
    GetDocumentMappingActions |
    GetConfigMiscActions | SaveConfigMiscActions;