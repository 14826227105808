import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { Box, Tab, Tabs } from "@mui/material";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import OtherDocumentBundling from "./OtherDocumentBundling";
import { unsignedDocColumns, signedDocColumns } from "./configbundlingdata";
import { IDocumentBundle } from "../../../models/configuration/bundles/IBundles";
import ConfigLoad from "../../../components/shared/config/ConfigLoad";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import EncompassService from "../../../services/encompassService";
import ConfigDocumentHandlingDialog from "./ConfigDocumentHandlingDialog";
import ConfirmationDialog from "../../../components/shared/ConfirmDialog";
import ConfigTopBar from "../../../components/shared/config/ConfigTopBar";
import "../../../assets/style.css";
import AlertSnackbar from "../../../components/shared/AlertSnackbar";
import ConfigBottomBar from "../../../components/shared/config/ConfigBottomBar";

const ConfigBundling: React.FC = () => {
    const [tabValue, setTabValue] = React.useState(0);

    // Reducers
    const { getConfigBundleDocumentData, saveConfigBundleData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configDocumentBundles);
    const [selectedConfigId, setSelectedConfigId] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");

    // State
    const [unsignedDocBundles, setUnsignedDocBundles] = useState<Array<IDocumentBundle>>([]);
    const [signedDocBundles, setSignedDocBundles] = useState<Array<IDocumentBundle>>([]);
    const [selectedBundle, setSelectedBundle] = useState<IDocumentBundle | null>(null);
    const [editingBundle, setEditingBundle] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [currentSelectedBundle, setCurrentSelectedBundle] = useState(null);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    // Refs
    const hasFetched = useRef(false);

    const fetchBundles = useCallback((configId: string) => {
        getConfigBundleDocumentData(configId, accessToken);
    }, [getConfigBundleDocumentData, accessToken]);

    useEffect(() => {

        if (!hasFetched.current) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            fetchBundles(configId);
            hasFetched.current = true;
        }
    }, [fetchBundles]);

    // Handle Fetched Data
    useEffect(() => {
        if (!saveError && data != null) {
            openAlert("Successfully Loaded Bundling", 'success');
            setUnsignedDocBundles(
                data.Configuration.Bundling.filter(
                    (x) => x.BundlingConfigurationType?.toLowerCase() === "unsigned"
                )
            );
            setSignedDocBundles(
                data.Configuration.Bundling.filter(
                    (x) => x.BundlingConfigurationType?.toLowerCase() === "signed"
                )
            );
        }
    }, [data, saveError]);

    function editItem(e: any, item: any) {
        setSelectedBundle(item);
        setEditingBundle(true);
    }

    function deleteItem(e: any, item: any) {
        setCurrentSelectedBundle(item);
        setConfirmDialogOpen(true);
    }

    function deleteBundle(item: any) {
        var docindex = unsignedDocBundles.indexOf(item);
        if (docindex > -1) {
            setUnsignedDocBundles([
                ...unsignedDocBundles
                    .slice(0, docindex)
                    .concat(unsignedDocBundles.slice(docindex + 1)),
            ]);
        } else {
            docindex = signedDocBundles.indexOf(item);
            if (docindex > -1) {
                setSignedDocBundles([
                    ...signedDocBundles
                        .slice(0, docindex)
                        .concat(signedDocBundles.slice(docindex + 1)),
                ]);
            }
        }

        setCurrentSelectedBundle(null);
        setConfirmDialogOpen(false);
    }

    function cancelDeleteBundle() {
        setConfirmDialogOpen(false);
        setCurrentSelectedBundle(null);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const save = async () => {
        try {
            const combinedDocBundles = [...unsignedDocBundles, ...signedDocBundles];
            console.log("🚀 ~ save ~ combinedDocBundles:", combinedDocBundles);
            await saveConfigBundleData(selectedConfigId, combinedDocBundles, accessToken);
            openAlert("Successfully Saved Changes", 'success');
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            console.error("Error saving data", e);
            setSaveError(true);
            setErrorMessage(e instanceof Error ? `Failed to save data: ${e.message}` : 'Failed to save data: Unknown error');
            openAlert("Failed to Save Changes", 'error');
        }
    }

    // Alert] Handling 
    const handleSnackBarClose = () => setAlertOpen(false);
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    };

    return (
        <>
            <section>
                <div>
                    {loading && (
                        <ConfigLoad loading={loading} message="Loading Configuration" />
                    )}
                </div>

                <Box
                    component="main"
                    sx={{
                        m: 2,
                        p: 2,
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        overflow: "auto",
                        height: "100%",
                    }}
                >
                    <ConfigTopBar save={save} error={error || errorMessage || null} />

                    <>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Unsigned Documents" {...docPanelProps(0)} />
                            <Tab label="Signed Documents" {...docPanelProps(1)} />
                            <Tab label="Other Documents" {...docPanelProps(2)} />
                        </Tabs>

                        <DocsTabPanel value={tabValue} index={0}>
                            <DTDataGrid
                                data={unsignedDocBundles}
                                columns={unsignedDocColumns(editItem, deleteItem)}
                                tableName="unsigneddocs"
                                containerSx={{ maxHeight: "60vh" }}
                            />
                        </DocsTabPanel>
                        <DocsTabPanel value={tabValue} index={1}>
                            <DTDataGrid
                                data={signedDocBundles}
                                columns={signedDocColumns(editItem, deleteItem)}
                                tableName="signeddocs"
                                containerSx={{ maxHeight: "60vh" }}
                            />
                        </DocsTabPanel>
                        <DocsTabPanel value={tabValue} index={2}>
                            <OtherDocumentBundling />
                        </DocsTabPanel>
                    </>
                    <ConfigBottomBar save={save} />
                </Box>
            </section>
            {selectedBundle && editingBundle}
            {
                <ConfigDocumentHandlingDialog
                    open={editingBundle}
                    documentBundle={selectedBundle!}
                    closeFxtn={() => setEditingBundle(false)}
                />
            }

            <ConfirmationDialog
                open={confirmDialogOpen}
                title="Confirm Delete"
                message="Are you sure you wish to delete this item?"
                onConfirm={() => deleteBundle(currentSelectedBundle)}
                onCancel={() => cancelDeleteBundle()}
            />

            <AlertSnackbar
                alertOpen={alertOpen}
                alertMessage={alertMessage}
                alertSeverity={alertSeverity}
                handleSnackBarClose={handleSnackBarClose}
            />
        </>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function DocsTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`bundle-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1, height: 1 }}>{children}</Box>}
        </div>
    );
}

function docPanelProps(index: number) {
    return {
        id: `bundle-tab-${index}`,
    };
}

export default ConfigBundling;