import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IEventBundleConfiguration } from "../../models/configuration/bundles/IBundles";

// This action creator gets the server config values from an api call or state
const getConfigEventBundleData = (configId: string, token: string | null) => {
    return async (dispatch: Dispatch<Action>) => {
      
        dispatch({ 
            type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA
        });
        
        const pluginService = new PluginService();
  
        try {
            const payload = await pluginService.getConfig<IEventBundleConfiguration>(token, "event-bundling", configId);
            
            dispatch({
                type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS,
                payload: payload,
            });
        
        } catch (error: any) {
            
            console.error("Get Config Event Data");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to fetch Config Event Bundle Data: (${error.message})`;
                dispatch({
                  type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE,
                  payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getConfigEventBundleData;