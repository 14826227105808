import { IBundleConfiguration } from "../../models/configuration/bundles/IBundles";
import { Action, ActionType } from "../actions";

interface ConfigBundlesReducer {
  loading: boolean;
  error: string | null;
  data: IBundleConfiguration | null;
}

const initialState = { loading: false, error: null, data: null };

const configDocumentBundlesReducer = (
  state: ConfigBundlesReducer = initialState,
  action: Action
): ConfigBundlesReducer => {
  switch (action.type) {
    // GET DATA
    case ActionType.GET_CONFIG_BUNDLE_DATA:
      return { loading: true, error: null, data: null };
    case ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE:
      return { loading: false, error: action.payload, data: null };
    case ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS:
      return { loading: false, error: null, data: action.payload };

    // SAVE DATA
    case ActionType.SAVE_CONFIG_BUNDLE_DATA:
      return { ...state, loading: true, error: null, data: state.data };
    case ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE:
      return { loading: false, error: action.payload, data: state.data };
    case ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS:
      return { loading: false, error: null, data: action.payload };

    default:
      return state;
  }
};

export default configDocumentBundlesReducer;