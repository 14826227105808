import { IImpersonation } from "../../models/configuration/plugin/IConfiguration";
import { Action, ActionType } from "../actions";

interface ImpersonationState {
    loading: boolean;
    error: string | null;
    data: IImpersonation | null;
}

const initialState = { loading: false, error: null, data: null }

const configImpersonationReducer = (
    state: ImpersonationState = initialState,
    action: Action
): ImpersonationState => {

    switch (action.type) {
        // GET DATA
        case ActionType.GET_IMPERSONATION: 
            return { loading: true, error: null, data: null };
        case ActionType.GET_IMPERSONATION_FAILURE: 
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_IMPERSONATION_SUCCESS:
        return { loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configImpersonationReducer;