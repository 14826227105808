import React, { useEffect, useRef, useState } from "react";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useSelector } from "react-redux";
import { IErrorMessageOverrides } from "../../models/configuration/plugin/IConfiguration";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import "../../assets/style.css";
import RespMessages from "../../components/configuration/messages/respMessages";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";

const ConfigResponseMessages: React.FC = () => {

    // State 
    const { getConfigMessagesData, saveConfigMessagesData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configMessages);
    const [saving, setSaving] = useState<boolean>(false);

    // Send Validations State
    const [messages, setMessages] = useState<IErrorMessageOverrides[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');

    // Refs
    const hasFetchedData = useRef(false);

    // Fetch Data
    const fetchPageData = (configId: string) => {
        if (!hasFetchedData.current) {
            setSelectedConfigId(configId);
            getConfigMessagesData(configId, accessToken);
            hasFetchedData.current = true;
        }
    }

    // Handle Fetched Data
    useEffect(() => {
        if (!saveError && data) {
            setMessages(data.Configuration?.ErrorMessageOverrides);
        }
    }, [data, saveError]);

    // Reducing the useEffect need 
    const configId = EncompassService.getConfigId() as string;
    fetchPageData(configId);

    const updateMessages = (updatedMessages: IErrorMessageOverrides[]) => {
        setMessages(updatedMessages);
    }

    const save = async () => {
        try {
            setSaving(true);
            await saveConfigMessagesData(selectedConfigId, messages, accessToken);
            openAlert("Saved Changes", "success");
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            setSaveError(true);
            setErrorMessage(error);
            setSaving(false);
        } finally {
            setSaving(false);
        }
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <>
            <section>
                <div>
                    {(loading || saving) 
                        && 
                        (<ConfigLoad 
                                loading={loading || saving} 
                                message={loading ? "Loading Configuration": "Saving Configuration"} />)}
                </div>
                <Box
                    mt={1} component="main" sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                    }}
                >
                    
                    <ConfigTopBar save={save} error={error || errorMessage || null} />
                    <RespMessages messages={messages}updateMessages={updateMessages} />
                    <ConfigBottomBar save={save} />                        

                </Box>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={alertOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                >
                    <MuiAlert
                        onClose={handleSnackBarClose}
                        severity={alertSeverity}
                        sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                        {alertMessage}
                    </MuiAlert>
                </Snackbar>
            </section>
        </>
    );
};

export default ConfigResponseMessages;