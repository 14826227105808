import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IDefaultValues, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

// This action creator gets the server config values from an api call or state
const saveConfigMiscData = (configId: string, updatedMiscDefaultData: IDefaultValues, token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {
      
        dispatch({ type: ActionType.SAVE_CONFIG_MISC });
        
        try {
            
            const { data: pluginConfiguration } = getState().configMiscDefaults;
            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            if (!updatedMiscDefaultData) {
                throw new Error("No Misc Defaults to save");
            }

            const updatedConfig = { 
                ...pluginConfiguration.Configuration,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                DefaultValues: updatedMiscDefaultData
            }
            
            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            }

            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);
            
            dispatch({
                type: ActionType.SAVE_CONFIG_MISC_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });
        
            return Promise.resolve();
        } catch (error: any) {
            console.error("Save Misc Defaults Data");
            const payloadErrorMessage = `Failed to save Misc Defaults Data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_CONFIG_MISC_FAILURE,
                payload: payloadErrorMessage,
            });
            return Promise.reject(payloadErrorMessage);   
        }
    }
}

export default saveConfigMiscData;