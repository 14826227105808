import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import { Action, ActionType } from "../actions";

interface MessageState {
    loading: boolean;
    error: string | null;
    data: IPluginConfiguration | null;
}

const initialState = { loading: false, error: null, data: null }

const configMessagesReducer = (
    state: MessageState = initialState,
    action: Action
): MessageState => {

    switch (action.type) {
        // GET DATA
        case ActionType.GET_MESSAGES:
            return { loading: true, error: null, data: null };
        case ActionType.GET_MESSAGES_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_MESSAGES_SUCCESS:
            return { loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configMessagesReducer; 